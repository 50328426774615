<template>
    <div>
        <div v-if="authUserPermission['stock-calc-fast-by-demand']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col-12">
                                <h3>Rekomendasi Stok Sparepart Optimum for Single Material Code</h3>
                                <el-dialog
                                  title="Matrix Perhitungan"
                                  :visible.sync="matrixDialogVisible"
                                  width="70%"
                                  :close-on-click-modal="false"
                                  @close="handleDialogClose"
                                >
                                  <div class="table-container">
                                    <table class="matrix-table">
                                      <thead>
                                        <tr>
                                          <th rowspan="5" class="variable-header" style="width: 170px">
                                            VARIABEL
                                          </th>
                                          <th colspan="7" class="method-header">METODE</th>
                                          <th
                                            rowspan="4"
                                            class="bcr-header"
                                            style="background-color: #1579ab; width: 90px"
                                          >
                                            BCR
                                          </th>
                                        </tr>
                                        <tr>
                                          <th colspan="3" class="sub-header">N=0</th>
                                          <th class="sub-header">N &lt; 30</th>
                                          <th class="sub-header">N &gt; 30</th>
                                          <th colspan="2" class="sub-header">N &lt; 30 &amp; N&gt; 30</th>
                                        </tr>
                                        <tr>
                                          <th
                                            colspan="3"
                                            class="sub-header"
                                            style="background-color: #1579ab"
                                          >
                                            NON MOVING
                                          </th>
                                          <th class="sub-header" style="background-color: #1579ab">
                                            Poisson <br />(PP)
                                          </th>
                                          <th
                                            class="sub-header"
                                            style="background-color: #1579ab; width: 60px"
                                          >
                                            Normal <br />(PN)
                                          </th>
                                          <th class="sub-header" style="background-color: #1579ab">
                                            Deterministik <br />(MD)
                                          </th>
                                          <th class="sub-header" style="background-color: #1579ab">
                                            Tak Tentu<br />(MT)
                                          </th>
                                        </tr>
                                        <tr>
                                          <th class="sub-header" style="width: 70px">Regret</th>
                                          <th class="sub-header" style="width: 115px">
                                            Liniear Kerusakan
                                          </th>
                                          <th class="sub-header" style="width: 140px">
                                            Non Liniear Kerusakan
                                          </th>
                                          <th class="sub-header" style="width: 65px">Poisson</th>
                                          <th class="sub-header" style="width: 65px">Model Q</th>
                                          <th class="sub-header" style="width: 80px">Tchebycheff</th>
                                          <th class="sub-header" style="width: 85px">Deterministik</th>
                                        </tr>
                                        <tr>
                                          <th class="sub-header" style="background-color: #1ad0b7">
                                            EOQ, <br />
                                            Ongkos <br />Inventory
                                          </th>
                                          <th class="sub-header" style="background-color: #1ad0b7">
                                            EOQ, Ongkos <br />
                                            Inventory
                                          </th>
                                          <th class="sub-header" style="background-color: #1ad0b7">
                                            EOQ, Ongkos <br />
                                            Inventory
                                          </th>
                                          <th class="sub-header" style="background-color: #1ad0b7">
                                            EOQ, ROP <br />
                                            SS, Min, <br> Max
                                          </th>
                                          <th class="sub-header" style="background-color: #1ad0b7">
                                            EOQ, ROP <br />
                                            SS, Min, <br> Max
                                          </th>
                                          <th class="sub-header" style="background-color: #1ad0b7">
                                            EOQ, ROP <br />
                                            TCO, Min,<br> Max
                                          </th>
                                          <th class="sub-header" style="background-color: #1ad0b7">
                                            EOQ, TCO
                                          </th>
                                          <th class="sub-header" style="background-color: #1ad0b7">
                                            Rekomendasi <br />Pembelian <br />
                                            Sparepart
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>*Demand (D)</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td>*Lead Time (L)</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td>*Unit Price (p)</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                        </tr>
                                        <tr>
                                          <td>**Stock Effect (Cu)</td>
                                          <td></td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td></td>
                                          <td>✓</td>
                                          <td>✓</td>
                                        </tr>
                                        <tr>
                                          <td>**Order Cost (A)</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td>**Holding Cost (h)</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td>**Suku Bunga (i)</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>✓</td>
                                        </tr>
                                        <tr>
                                          <td>***Serice Level</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td>Jumlah Komponen Terpasang (n)</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td>✓</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td>Sisa Tahun Pemakaian</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>✓</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <h5>
                                      Keterangan: N Merupakan jumlah adanya pengambilan dalam kurun 7
                                      tahun terakhir dalam satuan waktu bulan
                                    </h5>
                                  </div>
                                  <span slot="footer" class="dialog-footer">
                                    <el-button @click="matrixDialogVisible = false">Tutup</el-button>
                                  </span>
                                </el-dialog>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="col-16 mt-3">
                            <div class="d-flex flex-wrap align-items-start">
                                <div class="mr-2" style="flex: 0 0 145px; min-width: 100px;">
                                    <label class="form-control-label">
                                        <b>Gunakan Data TA </b>
                                        <el-tooltip placement="top">
                                            <template #content> Data z61 terkait kebutuhan sparepart untuk TA</template>
                                            <sup>
                                                <span style="font-size:10px"><i
                                                        class="fa fa-exclamation-circle"></i></span>
                                            </sup>
                                        </el-tooltip>
                                    </label>
                                    <base-input>
                                        <!-- <el-select v-model="dataTa" style="width: 100%; margin-top: 5px;"
                                            placeholder="Choose" :disabled="loadingCalculate || loadingRefresh"
                                            @change="handleDataTaChange">
                                            <el-option label="Ya" value="true"></el-option>
                                            <el-option label="Tidak" value="false"></el-option>
                                        </el-select> -->
                                        <el-select v-model="dataTa" style="width: 100%; margin-top: 5px;"
                                            placeholder="Choose" :disabled="loadingCalculate || loadingRefresh">
                                            <el-option label="Ya" value="true"></el-option>
                                            <el-option label="Tidak" value="false"></el-option>
                                        </el-select>
                                    </base-input>
                                </div>
                                <div class="mr-2" style="flex: 0 0 145px; min-width: 100px;">
                                    <label class="form-control-label">
                                        <b>Anak Perusahaan </b>
                                        <el-tooltip placement="top">
                                            <template #content> Anak perusahaan dalam PI Grup </template>
                                            <sup>
                                                <span style="font-size:10px">
                                                    <i class="fa fa-exclamation-circle"></i>
                                                </span>
                                            </sup>
                                        </el-tooltip>
                                    </label>
                                    <base-input>
                                        <!-- <el-select v-model="dataFitting.plant" filterable
                                            style="width: 100%; margin-top: 5px;" placeholder="Pilih Plant"
                                            :disabled="loadingCalculate || loadingRefresh">
                                            <el-option v-for="plant in plants" :key="plant.code" :value="plant.code"
                                                :label="`${plant.name}`"></el-option>
                                        </el-select> -->
                                        <el-select v-model="dataFitting.plant" filterable
                                            style="width: 100%; margin-top: 5px;" placeholder="Pilih Plant"
                                            :disabled="loadingCalculate || loadingRefresh">
                                            <el-option v-for="plant in plants" :key="plant.code" :value="plant.code"
                                                :label="`${plant.name}`"></el-option>
                                        </el-select>
                                    </base-input>
                                </div>
                                <div class="mr-2" style="flex: 0 0 170px; min-width: 250px;">
                                    <label class="form-control-label">
                                        <b>Material Number </b>
                                        <el-tooltip placement="top">
                                            <template #content>Material Spare Part MRO (6XXXXX)</template>
                                            <sup>
                                                <span style="font-size:10px">
                                                    <i class="fa fa-exclamation-circle"></i>
                                                </span>
                                            </sup>
                                        </el-tooltip>
                                    </label>
                                    <base-input>
                                        <el-autocomplete v-model="searchQuery" style="width: 100%; margin-top: 5px;"
                                            :fetch-suggestions="fetchSuggestionsMasterMaterialNumber"
                                            placeholder="Search Material Number" :loading="loadingSearch"
                                            @select="handleSelectMaterialNumber" :trigger-on-focus="false"
                                            @input="handleInputChange">
                                            <template slot-scope="{ item }">
                                                <div>{{ item.label }}</div>
                                            </template>
                                        </el-autocomplete>

                                        <!-- sementara pakai ini karena material number return array kosong -->
                                        <!-- <el-input v-model="searchQuery" style="width: 100%; margin-top: 5px;" @input="handleInputChange(), handleSelectMaterialNumber()"/> -->
                                            
                                    </base-input>
                                </div>
                                <div class="d-flex align-items-center"
                                    style="flex: 1; min-width: 200px; margin-top: 35px;">
                                    <base-button 
                                        type="success" 
                                        @click="handleFittingData"
                                        :disabled="isFittingDataDisabled" 
                                        :loading="loadingFittingData" 
                                        
                                    >
                                        Fitting Data
                                    </base-button>

                                    <base-button type="danger" @click="resetParameter"
                                        :disabled="disableDownload || loadingDownload || loadingRefresh">
                                        Reset
                                        <el-tooltip placement="top">
                                            <template #content> Mereset ulang inputan </template>
                                            <sup>
                                                <span style="font-size:10px"><i class="fa fa-exclamation-circle"></i>
                                                </span>
                                            </sup>
                                        </el-tooltip>
                                    </base-button>

                                    <base-button type="warning" @click="openMatrixDialog">
                                        Matrix Perhitungan<i class="fas fa-chart-line"></i>
                                        <el-tooltip placement="top">
                                            <template #content> Memunculkan matrix kebutuhan data untuk <br/> tipe
                                                material data</template>
                                            <sup><span style="font-size:10px"><i
                                                        class="fa fa-exclamation-circle"></i></span></sup>
                                        </el-tooltip> 
                                    </base-button>
                                </div>
                            </div>
                            <!-- dimand request -->

                            <div class="table-responsive mt-1" v-if="openDemandRate">
                                <!-- v-if="fittingDataResult.kategori == 'Pola Normal' || fittingDataResult.kategori === 'Pola Poisson' || fittingDataResult.kategori === 'Pola Tak - Tentu' || fittingDataResult.kategori === 'Pola Deterministik'" -->
                                <table class="table table-bordered customTableInputKecil table-striped">
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th v-for="(item, index) in allMonthsData" :key="'header-' + index">
                                                {{ item.monthYear }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="align-middle">
                                                Demand Request
                                                <el-tooltip placement="top">
                                                    <template #content>Demand request dari data 261 atau z61 dengan 
                                                        <br>time frame 5 tahun terakhir yang 
                                                        <br>diagregasikan per kebutuhan / hari</template>
                                                    <sup><span style="font-size:10px"><i
                                                                class="fa fa-exclamation-circle"></i></span></sup>
                                                </el-tooltip>
                                            </td>
                                            <td v-for="(item, index) in allMonthsData" :key="'request-' + index">
                                                <el-tooltip :content="'Bulan: ' + item.monthYear" placement="top">
                                                    <input class="form-control" placeholder="0"
                                                        v-model.number="demandRequest[index]"
                                                        :disabled="loadingCalculate || loadingRefresh" type="number" />
                                                </el-tooltip>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="align-middle">
                                                History Demand
                                                <el-tooltip placement="top">
                                                    <template #content>Demand request dari history <br>data pemakaian rutin ditampilkan per bulan</template>
                                                    <sup><span style="font-size:10px"><i
                                                                class="fa fa-exclamation-circle"></i></span></sup>
                                                </el-tooltip>
                                            </td>
                                            <!-- Tampilkan history demand rate -->
                                            <td v-for="(item, index) in allMonthsData" :key="'demand-history-' + index">
                                                <input class="form-control" :placeholder="item.value"
                                                    :value="item.value" disabled type="number" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Kolom untuk Unit Price, Initial Inventory, Metode ROQ, dan Order Cost dengan latar belakang abu-abu -->
                            <div class="row mt-4" v-if="openTableDirekomendasikan">
                                <div class="col-12">
                                    <div class="p-3" style="background-color: #e3e3e3">
                                        <div class="row">
                                            <div class="col-12">
                                                <label class="form-control-label">
                                                    <b>Direkomendasikan metode:</b> <span>{{ fittingDataResult.kategori}}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <!-- Kolom Unit Price -->
                                            <div class="col-md-2 mb-3"
                                                v-if="['Pola Normal', 'Pola Tak - Tentu', 'Pola Poisson', 'Pola Deterministik', 'Pola Non - Moving'].includes(fittingDataResult.kategori)">
                                                <label class="form-control-label"><b>Unit Price </b>
                                                    <el-tooltip placement="top">
                                                        <template #content> Harga 1 UoM sparepart dalam satuan rupiah
                                                        </template>
                                                        <sup><span style="font-size:10px"><i
                                                                    class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip>
                                                </label>
                                                <input class="form-control" style="width: 100%;"
                                                    placeholder="Unit Price" v-model="formattedUnitPrice" type="text"
                                                    :disabled="loadingCalculate || loadingRefresh">
                                            </div>

                                            <!-- Kolom Stock Out Effect -->
                                            <div class="col-md-2 mb-3"
                                                v-if="['Pola Normal', 'Pola Tak - Tentu', 'Pola Non - Moving', 'Pola Poisson'].includes(fittingDataResult.kategori)">
                                                <label class="form-control-label"><b>Stock Out Effect </b>
                                                    <el-tooltip placement="top">
                                                        <template #content> Ongkos kerugian akibat ketidaktersediaan
                                                            <br> sparepart per hari </template>
                                                        <sup><span style="font-size:10px"><i
                                                                    class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip>
                                                </label>
                                                <input class="form-control" style="width: 100%;"
                                                    placeholder="Stock Out Effect" v-model="formattedStockOutEffect"
                                                    type="text" :disabled="loadingCalculate || loadingRefresh">
                                            </div>

                                            <!-- Kolom Order Cost -->
                                            <div class="col-md-2 mb-3"
                                                v-if="['Pola Normal', 'Pola Poisson', 'Pola Deterministik'].includes(fittingDataResult.kategori)">
                                                <label class="form-control-label" style="font-size: 0.858vw"><b>Order Cost </b>
                                                    <el-tooltip placement="top">
                                                        <template #content>Harga tiap melakukan pemesanan suatu spare part dalam satuan rupiah</template>
                                                        <sup><span style="font-size:10px"><i
                                                                    class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip>
                                                </label>
                                                <input class="form-control" style="width: 100%;"
                                                    placeholder="Order Cost" v-model="formattedOrderCost" type="text"
                                                    :disabled="loadingCalculate || loadingRefresh">
                                            </div>
                                            <!-- :disabled="loadingCalculate || loadingRefresh" -->

                                            <!-- Kolom Holding Cost (%) -->
                                            <div class="col-md-2 mb-3"
                                                v-if="['Pola Normal', 'Pola Poisson', 'Pola Deterministik'].includes(fittingDataResult.kategori)">
                                                <label class="form-control-label" style="font-size: 0.858vw"><b>Holding Cost (%) </b>
                                                    <el-tooltip placement="top">
                                                        <template #content> Harga penyimpanan suatu spare part dalam
                                                            <br> satuan rupiah. Asumsi X % dari <br> harga
                                                            satuan</template>
                                                        <sup><span style="font-size:10px"><i
                                                                    class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip>
                                                </label>
                                                <input class="form-control" placeholder="Holding Cost (%)" type="text"
                                                    v-model="formattedHoldingCost"
                                                    :disabled="loadingCalculate || loadingRefresh">
                                            </div>

                                            <!-- Kolom Komponen Terpasang -->
                                            <div class="col-md-2 mb-3"
                                                v-if="fittingDataResult.kategori === 'Pola Non - Moving'">
                                                <label class="form-control-label" style="font-size: 0.858vw"><b>Komponen Terpasang</b>
                                                    <el-tooltip placement="top">
                                                        <template #content> Jumlah item sparepart yang terpasang dalam seluruh equipment <br>
                                                        yang sedang bekerja dalam satuan unit <br>
                                                        Jumlah komponen terpasang di seluruh Anper diasumsikan minimal 5 dan maksimal 10 unit
                                                        </template>
                                                        <sup><span style="font-size:10px"><i
                                                                    class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip>
                                                </label>
                                                <input class="form-control" style="width: 100%;"
                                                    placeholder="Komponen Terpasang"
                                                    v-model.number="installedComponents" type="number"
                                                    :disabled="loadingCalculate || loadingRefresh">
                                                <!-- <input class="form-control" style="width: 100%;"
                                                    placeholder="Komponen Terpasang"
                                                    v-model.number="installedComponents" type="number"
                                                    :disabled="loadingCalculate || loadingRefresh"> -->
                                            </div>

                                            <!-- Kolom Service Level -->
                                            <div class="col-md-2 mb-3"
                                                v-if="['Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)">
                                                <label class="form-control-label"><b>Service Level </b>
                                                    <el-tooltip placement="top">
                                                        <template #content> Kemampuan memberikan pelayanan kepada user
                                                            <br> pemeliharaan yang diukur berdasarkan rasio antara <br>
                                                            tingkat ketersediaan (availability) Material <br> Stock
                                                            dengan frekuensi permintaan </template>
                                                        <sup><span style="font-size:10px"><i
                                                                    class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip>
                                                </label>
                                                <input class="form-control" style="width: 100%;"
                                                    placeholder="Service Level (%)" v-model.number="serviceLevel"
                                                    type="number"
                                                    :disabled="isServiceLevelDisabled || loadingCalculate || loadingRefresh">
                                            </div>

                                            <!-- Kolom Lead Time -->
                                            <div class="col-md-2 mb-3"
                                                v-if="['Pola Poisson', 'Pola Normal', 'Pola Deterministik'].includes(fittingDataResult.kategori)">
                                                <label class="form-control-label">
                                                    <b>Lead Time (Bulan) </b>
                                                    <el-tooltip placement="top" effect="dark">
                                                        <template #content>
                                                            Durasi waktu semenjak user (perencanaan pemeliharaan) <br>
                                                            menginformasikan permintaan Spare Part <br> sampai dengan
                                                            waktu
                                                            kedatangannya (PR-GR).<br>
                                                            Range leadtime material {{ fittingDataResult.material }}:
                                                            <br><br>
                                                            Maksimum: {{ fittingDataResult.lead_time_max.toFixed(2)
                                                            }}<br>
                                                            Rata-Rata: {{ fittingDataResult.lead_time.toFixed(2) }}<br>
                                                            Minimum: {{ fittingDataResult.lead_time_min.toFixed(2) }}
                                                        </template>
                                                        <sup>
                                                            <span style="font-size:10px">
                                                                <i class="fa fa-exclamation-circle"></i>
                                                            </span>
                                                        </sup>
                                                    </el-tooltip>
                                                </label>
                                                <input class="form-control" style="width: 100%;" placeholder="Lead Time"
                                                    v-model.number="formattedLeadTime" type="number"
                                                    :disabled="loadingCalculate || loadingRefresh">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Tombol Run Calculation -->
                                    <div class="text-right mt-3">
                                        <button 
                                            class="btn btn-success" 
                                            @click="runCalculate"
                                            :disabled="loadingCalculate || loadingRefresh || disabledCalculate"
                                            id="runCalculationButton">
                                            <span v-if="loadingCalculate">
                                                <i class="fa fa-spinner fa-spin"></i> Loading...
                                            </span>
                                            <span v-else>
                                                Run Calculation
                                            </span>
                                        </button>
                                    </div>

                                    <!-- Tabel Hasil Perhitungan -->
                                    <div v-if="openTableHasilPerhitunganDanSummary" class="mt-4">
                                        <table class="table table-bordered table-striped text-center"
                                            style="margin-bottom: 40px;">
                                            <thead>
                                                <tr>
                                                    <th>NO</th>
                                                    <th
                                                        v-if="['Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)">
                                                        Service Level
                                                        <el-tooltip placement="top">
                                                            <template #content> Kemampuan memberikan pelayanan kepada
                                                                user <br> pemeliharaan yang diukur berdasarkan rasio
                                                                <br> antara tingkat ketersediaan (availability) <br>
                                                                Material Stock dengan frekuensi <br>
                                                                permintaan.</template>
                                                            <sup><span style="font-size:10px"><i
                                                                        class="fa fa-exclamation-circle"></i></span></sup>
                                                        </el-tooltip>
                                                    </th>
                                                    <th
                                                        v-if="['Pola Normal', 'Pola Poisson', 'Pola Tak - Tentu', 'Pola Non - Moving'].includes(fittingDataResult.kategori)">
                                                        Safety Stock
                                                        <el-tooltip placement="top">
                                                            <template #content> Persediaan pengaman (buffer stock), yang
                                                                berguna <br> untuk menjaga kemungkinan terjadinya Stock
                                                                Out <br> yang diakibatkan karena penggunaan material
                                                                yang <br> melebihi perkiraan semula atau dikarenakan
                                                                Lead <br> Time pengiriman material yang lebih lama <br>
                                                                dari yang diperkirakan. </template>
                                                            <sup><span style="font-size:10px"><i
                                                                        class="fa fa-exclamation-circle"></i></span></sup>
                                                        </el-tooltip>
                                                    </th>
                                                    <th
                                                        v-if="['Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)">
                                                        ROP
                                                        <el-tooltip placement="top">
                                                            <template #content> Re-Order Point sebagai batas posisi
                                                                jumlah <br> stock di mana pembelian kembali material
                                                                <br> harus dilakukan untuk mengisi kembali <br> stock
                                                                gudang. </template>
                                                            <sup><span style="font-size:10px"><i
                                                                        class="fa fa-exclamation-circle"></i></span></sup>
                                                        </el-tooltip>
                                                    </th>
                                                    <th
                                                        v-if="['Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)">
                                                        EOQ
                                                        <el-tooltip placement="top">
                                                            <template #content> Jumlah unit yang harus dipesan untuk mendapatkan 
                                                            <br>keuntungan ekonomis serta kombinasi biaya pemesanan 
                                                            <br> dan biaya penyimpanan yang paling minimum</template>
                                                            <sup><span style="font-size:10px"><i
                                                                        class="fa fa-exclamation-circle"></i></span></sup>
                                                        </el-tooltip>
                                                    </th>
                                                    <th
                                                        v-if="['Pola Deterministik', 'Pola Normal', 'Pola Poisson', 'Pola Tak - Tentu', 'Pola Non - Moving'].includes(fittingDataResult.kategori)">
                                                        UoM
                                                        <el-tooltip placement="top">
                                                            <template #content> Satuan yang digunakan dalam perhitungan </template>
                                                            <sup><span style="font-size:10px"><i
                                                                        class="fa fa-exclamation-circle"></i></span></sup>
                                                        </el-tooltip>
                                                    </th>
                                                    <th
                                                        v-if="['Pola Deterministik', 'Pola Normal', 'Pola Poisson', 'Pola Non - Moving'].includes(fittingDataResult.kategori)">
                                                        Total Cost Optimal
                                                        <el-tooltip placement="top">
                                                            <template #content> Ongkos pemakain pada suatu periode</template>
                                                            <sup><span style="font-size:10px"><i
                                                                        class="fa fa-exclamation-circle"></i></span></sup>
                                                        </el-tooltip>
                                                    </th>
                                                    <th
                                                        v-if="['Pola Tak - Tentu', 'Pola Non - Moving', 'Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)">
                                                        {{['Pola Tak - Tentu', 'Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori) ? 'Kategori' : 'Metode' }}
                                                        <el-tooltip
                                                            placement="top">
                                                            <template #content> Metode yang digunakan untuk melakukan perhitungan,<br> disesuaikan dengan karakteristik demand dari sparepart </template>
                                                            <sup><span style="font-size:10px"><i
                                                                        class="fa fa-exclamation-circle"></i></span></sup>
                                                        </el-tooltip>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(result, index) in calculationResults" :key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td
                                                        v-if="['Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)">
                                                        {{ result.service_level }}
                                                    </td>
                                                    <td
                                                        v-if="['Pola Normal', 'Pola Poisson', 'Pola Tak - Tentu', 'Pola Non - Moving'].includes(fittingDataResult.kategori)">
                                                        {{ result.safety_stock }}
                                                    </td>
                                                    <td
                                                        v-if="['Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)">
                                                        {{ result.ROP }}
                                                    </td>
                                                    <td
                                                        v-if="['Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)">
                                                        {{ result.EOQ }}
                                                    </td>
                                                    <td
                                                        v-if="['Pola Deterministik', 'Pola Normal', 'Pola Poisson', 'Pola Tak - Tentu', 'Pola Non - Moving'].includes(fittingDataResult.kategori)">
                                                        {{ fittingDataResult.unit_of_measurement ? fittingDataResult.unit_of_measurement : '-' }}
                                                    </td>
                                                    <td
                                                        v-if="['Pola Deterministik', 'Pola Normal', 'Pola Poisson', 'Pola Non - Moving'].includes(fittingDataResult.kategori)">
                                                        {{ formatRupiah(result.total_cost_optimal) }}
                                                    </td>
                                                    <td
                                                        v-if="['Pola Tak - Tentu', 'Pola Non - Moving', 'Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)">
                                                        {{ ['Pola Tak - Tentu', 'Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori) ? result.kategori : result.metode }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="mt-3">
                                            <div class="d-flex flex-column align-items-end">
                                                <div class="ml-2">
                                                    <base-button size="md" @click="runDownload"
                                                        :disabled="loadingDownload" type="dark">
                                                        <span v-if="loadingDownload">Downloading...</span>
                                                        <span v-else>Download</span>
                                                        <el-tooltip placement="top">
                                                        <template #content>Download dataset berdasarkan anak perusahaan dan nomor material yang dipilih</template>
                                                        <sup><span style="font-size:10px"><i
                                                                    class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip>
                                                    </base-button>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <h3 class="text-center" style="margin-top: 20px; margin-bottom: 20px;">
                                            <b>Summary</b>
                                        </h3>
                                        <div class="py-3"
                                            style="border: 1px solid #858585; border-radius: 5px; text-align: center;">
                                            <p>
                                                Berdasarkan hasil perhitungan di atas, dapat disimpulkan bahwa
                                                dalam 12 bulan ke depan.<br>
                                                disarankan nilai:<br>
                                                <span
                                                    v-if="['Pola Normal', 'Pola Poisson', 'Pola Tak - Tentu', 'Pola Non - Moving'].includes(fittingDataResult.kategori)">
                                                    <b>Safety Stock:</b> {{ calculationResult.safety_stock + ' ,' ?? ''}}
                                                </span>
                                                <span
                                                    v-if="['Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)">
                                                    <b>Reorder Point (ROP):</b> {{ calculationResult.ROP + ' ,' ?? ''}}
                                                </span>
                                                <span
                                                    v-if="['Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)">
                                                    <b>Economic Order Quantity (EOQ):</b> {{ calculationResult.EOQ }}
                                                </span><br>Berdasarkan Analisa SHP, material ini merupakan material
                                                <b>{{ calculationResult.stockNonStock }}</b>
                                                <br>Perhitungan Memperhitungkan data TA
                                            </p>
                                        </div> -->
                                        <h3 class="text-center" style="margin-top: 20px; margin-bottom: 20px;">
                                            <b>Summary</b>
                                        </h3>
                                        <div class="py-3" style="border: 1px solid #858585; border-radius: 5px; text-align: center;">
                                            <p style="margin: 0;">
                                                Berdasarkan hasil perhitungan di atas, dapat disimpulkan bahwa
                                                disarankan nilai:<br>
                                                
                                                <!-- Safety Stock for Non-Moving Pola (Vertical) -->
                                                <span v-if="fittingDataResult.kategori === 'Pola Non - Moving'">
                                                    <div v-for="(result, index) in calculationResults.filter(res => res.kategori === 'Pola Non - Moving')" :key="index" style="display: block; margin: 0; padding: 0;">
                                                        <b>Safety Stock dengan metode {{ result.metode }}:</b> <b>{{ result.safety_stock }}</b>
                                                    </div>
                                                </span>
                                                
                                                <!-- Safety Stock for Other Categories (Horizontal) -->
                                                <span v-else-if="['Pola Normal', 'Pola Poisson', 'Pola Tak - Tentu'].includes(fittingDataResult.kategori)">
                                                    <span style="display: inline-block;">
                                                        <b>Safety Stock:</b> <b>{{ calculationResult.safety_stock }}</b>
                                                    </span>
                                                </span>
                                                
                                                <!-- Reorder Point (ROP) -->
                                                <span v-if="['Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)">
                                                    <span style="display: inline-block;">
                                                        <span v-if="calculationResult.safety_stock">, </span><b>Reorder Point (ROP):</b> <b>{{ calculationResult.ROP }}</b>
                                                    </span>
                                                </span>
                                                
                                                <!-- Economic Order Quantity (EOQ) -->
                                                <span v-if="['Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)">
                                                    <span style="display: inline-block;">
                                                    <b>, Economic Order Quantity (EOQ):</b> <b>{{ calculationResult.EOQ }}</b>
                                                    </span>
                                                </span>
                                            </p>

                                            <!-- Additional text without extra spacing -->
                                            <p style="margin: 5px 0 0 0;">Berdasarkan Analisa SHP, material ini merupakan material <b>{{ calculationResult.stockNonStock }}</b></p>
                                            <p style="margin: 0;">Perhitungan Memperhitungkan data TA</p>
                                        </div>

                                        <!-- <div class="py-3" style="border: 1px solid #858585; border-radius: 5px; text-align: center;">
                                            <p style="margin: 0;">
                                                Berdasarkan hasil perhitungan di atas, dapat disimpulkan bahwa
                                                dalam 12 bulan ke depan.<br>
                                                disarankan nilai:<br>
                                                <span v-if="fittingDataResult.kategori === 'Pola Non - Moving'">
                                                    <span v-for="(result, index) in calculationResults.filter(res => res.kategori === 'Pola Non - Moving')" :key="index" style="display: block; margin: 0; padding: 0;">
                                                        <b>Safety Stock Di {{ result.metode }}:</b> <b>{{ result.safety_stock }}</b>
                                                    </span>
                                                </span>
                                                <span v-else-if="['Pola Normal', 'Pola Poisson', 'Pola Tak - Tentu'].includes(fittingDataResult.kategori)" style="display: block; margin: 0;">
                                                    <b>Safety Stock:</b> <b>{{ calculationResult.safety_stock }}</b>
                                                </span>
                                                <span v-if="['Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)" style="display: block; margin: 0;">
                                                    <b>Reorder Point (ROP):</b> <b>{{ calculationResult.ROP }}</b>
                                                </span>
                                                <span v-if="['Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(fittingDataResult.kategori)" style="display: block; margin: 0;">
                                                    <b>Economic Order Quantity (EOQ):</b> <b>{{ calculationResult.EOQ }}</b>
                                                </span>
                                            </p>
                                            <p style="margin: 5px 0 0 0;">Berdasarkan Analisa SHP, material ini merupakan material <b>{{ calculationResult.stockNonStock }}</b></p>
                                            <p style="margin: 0;">Perhitungan Memperhitungkan data TA</p>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <empty v-else></empty>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else />
    </div>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Chart from 'chart.js/dist/chart.js'
import SkeletonLoading from '../../components/SkeletonLoading.vue';
import * as XLSX from 'xlsx';
import configUrl from '@/configs/config'
export default {
    components: { SkeletonLoading },
    data() {
        return {
            deskripsi: '',
            leadTime: 0,
            leadTimeMin: 0,
            leadTimeMax: 0,
            yearlyDemandRate: 0,
            unitPrice: 123000000,
            currentYear: new Date().getFullYear(),
            currentStock: 0,
            standarDeviasi: 0,
            filterYear: null,
            dataTa: null,
            dataFitting: {
                plant: null,
                materialNumber: null
            },
            materialNumbers: [],
            // demandRequest: Array(12).fill(0),
            demandHistory: Array(12).fill(0),
            searchQuery: '',
            plants: [
                { code: 'B601', name: 'PKG' },
                { code: 'C601', name: 'PKC' },
                { code: 'D601', name: 'PKT' },
                { code: 'E601', name: 'PIM' },
                { code: 'F601', name: 'PSP' }
            ],
            loadingCalculate: false,
            loadingRefresh: false,
            loadingFittingData: false,
            disableFittingData: false,
            disableDownload: false,
            loadingDownload: false,
            loadingSearch: false,
            matrixDialogVisible: false,
            fittingDataResult: {},
            calculationResult: null, //untuk ditampilkan pada summary
            calculationResults: {}, //untuk ditampilkan pada table hasil run calculation
            stockOutEffect: 3700000000,
            installedComponents: 5,
            orderCost: 0,
            holdingCost: 15,
            installedComponents: 0,
            serviceLevel: null,
            isServiceLevelDisabled: true, // Mulai dengan serviceLevel disabled
            interestRate: 0,
            disableCalculate: false,
            summary: {},
            selectedMaterialNumber: '',
            demandHistoryByMaterial: {}, // Object to store demand history by material number
            demandRequestByYear: {}, // Tambahkan ini untuk menyimpan demandRequest berdasarkan tahun
            canRecalculate: false, // Flag untuk mengatur status perhitungan
            demandRequestAllMonths: {},
            demandRequest: [],
            rawDataDemandData: {},
            total_cost_optimal: null, // Inisialisasi dengan null atau nilai default yang diperlukan
            apiUrl: configUrl.apiUrl,
            openTableHasilPerhitunganDanSummary: false,
            openDemandRate: false,
            openTableDirekomendasikan: false,
            stockNonStock: null, // Misalnya jika kamu menggunakannya di template
            stockStrategyData: []
        }

    },
    computed: {
        ...mapState({
            authUserPermission: state => state.auth.userPermission
        }),
        formattedHoldingCost: {
            get() {
                return `${this.holdingCost}%`; // Display holding cost as percentage
            },
            set(newValue) {
                // Remove percentage sign and convert to number
                const cleanedValue = parseFloat(newValue.replace('%', ''));
                this.holdingCost = isNaN(cleanedValue) ? 0 : cleanedValue;
            }
        },
        formattedUnitPrice: {
            get() {
                // Format angka menjadi Rupiah menggunakan toLocaleString dengan opsi IDR
                return this.unitPrice.toLocaleString('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0,
                }).replace('Rp', 'Rp.'); // Mengganti 'Rp' dengan 'Rp.' untuk format yang sesuai
            },
            set(newValue) {
                // Menghapus semua karakter selain angka untuk mengubahnya kembali menjadi angka murni
                const cleanedValue = newValue.replace(/[^0-9]/g, '');
                this.unitPrice = parseInt(cleanedValue, 10) || 0; // Jika parsing gagal, atur ke 0
            }
        },
        formattedOrderCost: {
            get() {
                return this.orderCost.toLocaleString('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0
                }).replace('Rp', 'Rp.'); // Format: Rp. 5.000.000
            },
            set(value) {
                // Hapus simbol 'Rp.' dan format sebagai angka
                const numberValue = parseInt(value.replace(/Rp\.|[^0-9]/g, ''), 10);
                this.orderCost = numberValue || 0;
            }
        },
        formattedStockOutEffect: {
            get() {
                return this.stockOutEffect.toLocaleString('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0
                }).replace('Rp', 'Rp.');
            },
            set(newValue) {
                // Menghilangkan format Rp. dan koma
                const cleanedValue = newValue.replace(/[^0-9]/g, '');
                this.stockOutEffect = parseInt(cleanedValue, 10) || 0;
            }
        },
        yearOptions() {
            const result = [];
            const currentYear = new Date().getFullYear();
            for (let i = 0; i <= 6; i++) {
                result.push(currentYear - i);
            }
            return result;
        },
        isFittingDataDisabled() {
            // return !this.filterYear || !this.dataTa || !this.dataFitting.plant || !this.dataFitting.materialNumber || this.loadingFittingData;
            return !this.dataTa || !this.dataFitting.plant || !this.dataFitting.materialNumber || this.loadingFittingData;
        },
        // summary
        summary() {
            const result = this.calculationResult;
            const kategori = this.fittingDataResult.kategori;

            let summary = {
                Service_Level: result.service_level || 0,
                Safety_Stock: result.safety_stock || 0,
                Reorder_Point: result.ROP || 0,
                ROQ: result.EOQ || 0,
                Minimum_Stock: 0, // Hitung jika perlu
                Maximum_Stock: 0, // Hitung jika perlu
                Message: 'Perhitungan selesai.'
            };

            return summary;
        },
        // end sahrul
        formattedLeadTime: {
            get() {
                return this.leadTime.toFixed(2);
            },
            set(value) {
                this.leadTime = parseFloat(value);
            }
        },
    },
    watch: {

        unitPrice(newValue) {
            console.log('unitPrice changed:', newValue);
            if (newValue > 100000000) {
                this.orderCost = 5000000; // 5 juta
            } else {
                this.orderCost = 1000000; // 1 juta
            }
            console.log('orderCost set to:', this.orderCost);
        },

        'calculationData': function (val) {
            if (val.length > 0) {
                let findData = this.calculationData[0]
                this.summary = findData;
            } else {
                this.summary = {}
            }
        },
        selectedMaterialNumber(newVal) {
            if (newVal) {
                this.fetchHistoryMonthly();
            }
        },
        demandRequest: {
            handler() {
                this.saveDemandRequest();
            },
            deep: true
        },
    },
    mounted() {
        // Ensure holdingCost starts with default value
        this.holdingCost = 15; // Default 15%

        this. installedComponents = 5;

        // Set initial orderCost based on the initial unitPrice
        if (this.unitPrice > 100000000) {
            this.orderCost = 5000000; // 5 juta
        } else {
            this.orderCost = 1000000; // 1 juta
        }
        this.filterYear = this.yearOptions.find(year => year === (this.currentYear - 1)) || this.yearOptions[0];
        this.fetchHistoryMonthly(); // No need for async/await since it's not blocking
        this.getStockStrategyFromCsv()
    },
    methods: {
        async handleFittingData() {
            this.openDemandRate = false
            this.openTableDirekomendasikan = false
            this.loadingFittingData = true
            this.openTableHasilPerhitunganDanSummary = false

            const postData = {
                "use_data_ta": this.dataTa === 'true',
                "plant": this.dataFitting.plant,
                "material_codes": [String(this.selectedMaterialNumber)]
            };

            var config = {
                method: 'POST',
                url: 'https://stock-calc-be-v2.pupuk.in/fit-data',
                headers: { "Content-Type": "application/json" },
                data: postData,
            };

            try {
                const response = await axios(config);
                this.fittingDataResult = response.data[0];
                if (response.data[0].deskripsi !== 'Data material 6001234 tidak ditemukan') {

                    // Update values from API response
                    this.unitPrice = this.fittingDataResult.unit_price || this.unitPrice; // Use value from API or default if null
                    this.leadTime = this.fittingDataResult.lead_time;
                    this.standarDeviasi = this.fittingDataResult.standar_deviasi;

                    // console.log('Fitting Data Result:', response.data[0]);

                    if (['Pola Normal', 'Pola Poisson', 'Pola Tak - Tentu', 'Pola Deterministik', 'Pola Non - Moving'].includes(this.fittingDataResult.kategori)) {
                        await this.fetchHistoryMonthly();
                    }
                } else {
                    this.$notify({
                        message: "Data material 6001234 tidak ditemukan.",
                        type: 'danger',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                }
            } catch (error) {
                this.$notify({
                    message: 'Error fetching fitting data: ' + error,
                    type: 'danger',
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
            } finally {
                this.loadingFittingData = false;
                if (['Pola Normal', 'Pola Tak - Tentu', 'Pola Poisson', 'Pola Deterministik'].includes(this.fittingDataResult.kategori)) {
                    this.openDemandRate = true
                }
                this.openTableDirekomendasikan = true
            }
        },

        async runCalculate() {
            if (!this.fittingDataResult || !this.fittingDataResult.kategori) {
                this.$notify({
                    message: 'fittingDataResult belum terisi dengan benar',
                    type: 'danger',
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
                return false;
            }
            this.openTableHasilPerhitunganDanSummary = false;
            this.loadingCalculate = true;
            this.isServiceLevelDisabled = true;

            if (['Pola Tak - Tentu', 'Pola Deterministik', 'Pola Normal', 'Pola Poisson'].includes(this.fittingDataResult.kategori)) {
                if ((!this.demandRequest || this.demandRequest.length === 0)) {
                    this.$notify({
                        message: 'demandRequest belum terisi atau kosong',
                        type: 'danger',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                    
                    this.loadingCalculate = false;
                    return false;
                }

                if (['Pola Normal', 'Pola Deterministik', 'Pola Poisson', 'Pola Tak - Tentu'].includes(this.fittingDataResult.kategori) && this.unitPrice === 0) {
                    this.$notify({
                        message: 'Unit Price tidak boleh 0',
                        type: 'danger',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                    this.loadingCalculate = false;
                    return false
                }

                if (['Pola Normal', 'Pola Deterministik', 'Pola Poisson'].includes(this.fittingDataResult.kategori) && this.holdingCost === 0) {
                        this.$notify({
                            message: 'Holding Cost tidak boleh 0',
                            type: 'danger',
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        });
                        this.loadingCalculate = false;
                        return false   
                }

                if (['Pola Normal', 'Pola Poisson'].includes(this.fittingDataResult.kategori) && this.orderCost === 0) {
                        this.$notify({
                            message: 'Order Cost tidak boleh 0',
                            type: 'danger',
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        });
                        this.loadingCalculate = false;
                        return false   
                }

                if (this.fittingDataResult.kategori === 'Pola Normal' && this.stockOutEffect === 0) {
                        this.$notify({
                            message: 'Stock Out Effect tidak boleh 0',
                            type: 'danger',
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        });
                        this.loadingCalculate = false;
                        return false   
                }
            }

            if (this.fittingDataResult.kategori === 'Pola Non - Moving' && this.installedComponents === 0) {
                this.$notify({
                    message: 'Komponen Terpasang tidak boleh 0',
                    type: 'danger',
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
                this.loadingCalculate = false;
                return false
            }

            const yearlyDemandRate = this.fittingDataResult.kategori !== 'Pola Non - Moving' ? this.calculateYearlyDemandRate() : undefined;

            const inputData = [
                {
                    "plant": String(this.dataFitting.plant),
                    "material": String(this.selectedMaterialNumber),
                    "kategori": String(this.fittingDataResult.kategori),
                    "input_parameter": {}
                }
            ];

            switch (this.fittingDataResult.kategori) {
                case 'Pola Normal':
                case 'Pola Poisson':
                    inputData[0].input_parameter = {
                        lead_time: parseInt(this.leadTime) / 12,
                        yearly_demand_rate: yearlyDemandRate,
                        unit_price: this.unitPrice,
                        standar_deviasi: this.standarDeviasi,
                        order_cost: this.orderCost,
                        holding_cost: this.holdingCost,
                        stock_out_effect: this.stockOutEffect,
                    };
                    if (this.serviceLevel !== null && this.serviceLevel !== undefined && this.serviceLevel !== "") {
                        inputData[0].input_parameter.service_level = this.serviceLevel;
                    }
                    break;

                case 'Pola Deterministik':
                    inputData[0].input_parameter = {
                        lead_time: parseInt(this.leadTime) / 12,
                        yearly_demand_rate: yearlyDemandRate,
                        unit_price: this.unitPrice,
                        standar_deviasi: this.standarDeviasi,
                        order_cost: this.orderCost,
                        holding_cost: this.holdingCost,
                        stock_out_effect: this.stockOutEffect
                    };
                    break;

                case 'Pola Non - Moving':
                    inputData[0].input_parameter = {
                        jumlah_komponen_terpasang: this.installedComponents,
                        unit_price: this.unitPrice,
                        stock_out_effect: this.stockOutEffect
                    };
                    break;

                case 'Pola Tak - Tentu':
                    inputData[0].input_parameter = {
                        yearly_demand_rate: yearlyDemandRate,
                        standar_deviasi: this.standarDeviasi,
                        unit_price: this.unitPrice,
                        stock_out_effect: this.stockOutEffect
                    };
                    break;

                default:
                    this.loadingCalculate = false;
                    this.isServiceLevelDisabled = false;
                    return;
            }

            try {
                const response = await axios.post('https://stock-calc-be-v2.pupuk.in/calculate', inputData, {
                    headers: { "Content-Type": "application/json" }
                });
                this.calculationResults = response.data;

                if (this.calculationResults.length > 0) {
                    // Set calculationResult to the first item or process as needed
                    this.calculationResult = this.calculationResults[0];
                }

                // try {
                //     const stockResponse = await axios.get(`${this.apiUrl}/get-stock-or-non-stock?material_code=${inputData[0].material}`);
                //     this.calculationResult.stockNonStock = stockResponse.data.data;
                // } catch (error) {
                //     this.calculationResult.stockNonStock = error.response.data.data;
                // }
                const stockResponse = this.stockStrategyData.find(item => item.material_code === parseInt(inputData[0].material, 10));
                stockResponse ? this.calculationResult.stockNonStock = stockResponse.display_stock_strategy : this.calculationResult.stockNonStock = 'Belum Dianalisa SHP'

                if (this.calculationResult.service_level) {
                    this.serviceLevel = this.calculationResult.service_level;
                }

                this.isServiceLevelDisabled = false;
            } catch (error) {
                console.log(error)
                this.$notify({
                    message: error.response.message,
                    type: 'danger',
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
            } finally {
                this.loadingCalculate = false;
                this.openTableHasilPerhitunganDanSummary = true;
            }
        },
        async fetchHistoryMonthly() {
            if (!this.selectedMaterialNumber || this.fittingDataResult.kategori === 'Pola Non - Moving') return;

            const postData = {
                "use_data_ta": this.dataTa === 'true',
                "plant": this.dataFitting.plant,
                "material": String(this.selectedMaterialNumber)
            };

            try {
                const response = await axios.post('https://stock-calc-be-v2.pupuk.in/history-monthly', postData, {
                    headers: { "Content-Type": "application/json" }
                })

                const { month, year } = this.getCurrentMonthYear()
                const firstDemandDate = new Date(response.data.first_demand_date)
                this.allMonthsData = []

                for (const responseYear in response.data.demand_data) {
                    if (parseInt(responseYear) > year) continue;

                    const monthlyData = response.data.demand_data[responseYear] || [];
                    monthlyData.forEach((value, index) => {
                        const currentDate = new Date(responseYear, index)

                        if (currentDate < firstDemandDate && currentDate.getMonth() !== firstDemandDate.getMonth()) return;
                        if (parseInt(responseYear) === year && index > month) return;

                        const monthYear = `${currentDate.toLocaleString('id', { month: 'short' })} ${responseYear}`;
                        this.allMonthsData.push({ monthYear, value });
                    });
                }

                this.initializeDemandRequest();
            } catch (error) {
                console.error('Error fetching history monthly:', error);
            }
        },
        getCurrentMonthYear() {
            const now = new Date();
            const month = now.getMonth(); // 0-11
            const year = now.getFullYear();
            return { month, year };
        },
        async fetchSuggestionsMasterMaterialNumber(queryString, cb) {
            if (!this.dataFitting.plant || queryString.length < 3) return;

            this.loadingSearch = true;
            try {
                const response = await axios.get('https://stock-calc-be-v2.pupuk.in/master-material', {
                    params: {
                        plant: this.dataFitting.plant,
                        material_code: queryString,
                    },
                });
                const results = response.data;
                cb(results.map(item => ({
                    value: item.MATERIAL,
                    label: `${item.MATERIAL} - ${item.DESCRIPTION}`,
                    MATERIAL: item.MATERIAL,
                    DESCRIPTION: item.DESCRIPTION,
                })));
            } catch (error) {
                console.error('Error fetching material numbers:', error);
                cb([]);
            } finally {
                this.loadingSearch = false;
            }
        },
        handleInputChange(value) {
            // dikarenakan master material hanya return array kosong
            // Trigger suggestions fetch manually
            // if (value.length >= 3) {
            //     this.fetchSuggestionsMasterMaterialNumber(value, suggestions => {
            //         this.$refs.autocomplete.setSuggestions(suggestions);
            //     });
            // }
        },
        formatRupiah(value) {
            if (value === null || value === undefined) return '';
            return `Rp ${Number(value).toLocaleString('id-ID')}`;
        },
        handlePlantChange(value) {
            this.dataFitting.plant = value;
        },
        handleSelectMaterialNumber(item) {
            // this.dataFitting.materialNumber = item.value;
            this.searchQuery = item.label; // Setel searchQuery dengan label yang dipilih
            this.selectedMaterialNumber = item.MATERIAL; // Simpan nomor material yang dipilih
            this.dataFitting.materialNumber = item.MATERIAL; // Simpan nomor material yang dipilih
            this.deskripsi = item.DESCRIPTION; // Simpan deskripsi yang dipilih jika diperlukan
            console.log('Selected Material Number:', item);

        },
        handleDataTaChange(value) {
            this.dataTa = value;
        },
        calculateYearlyDemandRate() {
            const currentYear = new Date().getFullYear(); // Tahun saat ini
            const filteredData = {};

            if (this.rawDataDemandData.length === 0) {
                this.$notify({
                    message: 'Gagal load demand rate',
                    type: 'danger',
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
                return 0
            }
            // Filter data untuk mengabaikan tahun yang belum selesai
            for (const year in this.rawDataDemandData) {
                if (parseInt(year) < currentYear) {
                    filteredData[year] = this.rawDataDemandData[year]
                }
            }

            const sumYears = Object.keys(filteredData).length;
            const monthlySums = Array(12).fill(0);
            for (const year in filteredData) {
                for (let i = 0; i < filteredData[year].length; i++) {
                    monthlySums[i] += filteredData[year][i]
                }
            }
            // Menghitung rata-rata untuk setiap bulan
            const monthlyAverages = monthlySums.map(sum => sum / sumYears);
            // Menghitung rata-rata tahunan
            const yearlyDemandAvgs = monthlyAverages.reduce((acc, avg) => acc + avg, 0)
            return yearlyDemandAvgs
        },
        saveDemandRequest() {
            if (this.fittingDataResult.kategori === 'Pola Non - Moving') return;

            const demandRequestAllYears = {};
            const monthMapping = {
                'Jan': 'Jan', 'Feb': 'Feb', 'Mar': 'Mar', 'Apr': 'Apr',
                'Mei': 'May', 'Jun': 'Jun', 'Jul': 'Jul', 'Agu': 'Aug',
                'Sep': 'Sep', 'Okt': 'Oct', 'Nov': 'Nov', 'Des': 'Dec'
            };

            // Simpan data request demand berdasarkan bulan
            this.allMonthsData.forEach((item, index) => {

                if (item?.monthYear && this.demandRequest[index] !== undefined) {
                    const [monthName, year] = item.monthYear.split(' ');
                    const englishMonthName = monthMapping[monthName];  // Konversi ke nama bulan Inggris
                    const monthIndex = new Date(`${englishMonthName} 1, ${year}`).getMonth();

                    if (!demandRequestAllYears[year]) {
                        // Memulai array pada tahun tertentu dengan menambahkan 0 untuk bulan sebelum material ini dibuat
                        demandRequestAllYears[year] = Array(monthIndex).fill(0);
                    }

                    // Add the demand value to the correct month position
                    demandRequestAllYears[year][monthIndex] = this.demandRequest[index];
                } else {
                    console.warn('Skipping item due to missing data:', item);
                }
            });
            this.rawDataDemandData = demandRequestAllYears
        },
        initializeDemandRequest() {
            if (this.fittingDataResult.kategori === 'Pola Non - Moving') {
                this.demandRequest = [];
                return;
            }

            this.demandRequest = this.allMonthsData.map(item => item.value || 0);
        },
        
        resetParameter() {
            this.deskripsi = '';
            this.leadTime = 0;
            this.leadTimeMin = 0;
            this.leadTimeMax = 0;
            this.yearlyDemandRate = 0;
            this.unitPrice = 123000000; // Atur ke default value
            this.installedComponents = 5;
            this.currentStock = 0;
            this.standarDeviasi = 0;
            this.filterYear = null;
            this.dataTa = null;
            this.dataFitting.plant = null;
            this.dataFitting.materialNumber = null;
            this.materialNumbers = [];
            this.demandRequest = Array(12).fill(0); // Pastikan ini di-reset dengan nilai default
            this.demandHistory = Array(12).fill(0);
            this.searchQuery = '';
            this.loadingCalculate = false;
            this.loadingRefresh = false;
            this.loadingFittingData = false;
            this.disableFittingData = false;
            this.disableDownload = false;
            this.loadingDownload = false;
            this.loadingSearch = false;
            this.matrixDialogVisible = false;
            this.fittingDataResult = {};
            this.calculationResult = null;
            this.stockOutEffect = 3700000000;
            this.orderCost = 0;
            this.holdingCost = 15;
            // this.installedComponents = 0;
            this.serviceLevel = null; // Pastikan ini sesuai dengan tipe data yang diharapkan
            this.isServiceLevelDisabled = true;
            this.interestRate = 0;
            this.disableCalculate = false;
            this.summary = {};
            this.selectedMaterialNumber = '';
            this.demandHistoryByMaterial = {};
            this.demandRequestByYear = {};
            this.canRecalculate = false;
            this.demandRequestAllMonths = {};
            this.rawDataDemandData = {};
            this.total_cost_optimal = null;
            this.apiUrl = configUrl.apiUrl;
            this.openTableHasilPerhitunganDanSummary = false;
            this.openDemandRate = false;
            this.openTableDirekomendasikan = false;
            this.stockNonStock = null; // Inisialisasi jika digunakan
        },
        pleaseCompleteDataNotify(title) {
            this.$notify({
                message: this.tt("please_complete_data", { title: title }),
                type: 'danger',
                verticalAlign: "bottom",
                horizontalAlign: "left"
            });
        },
        addServiceLevel() {
            if (this.serviceLevel.length == 0) {
                this.serviceLevel.push('')
            } else if (this.serviceLevel.length > 0) {
                if (this.serviceLevel[this.serviceLevel.length - 1] != '') {
                    this.serviceLevel.push('')
                } else {
                    this.pleaseCompleteDataNotify('Service Level')
                }
            }
        },
        async runDownload() {
            this.loadingDownload = true;
            try {
                // Cek apakah calculationResult adalah array atau objek tunggal
                const results = Array.isArray(this.calculationResults) ? this.calculationResults : [this.calculationResults];

                // Menentukan data yang akan dimasukkan ke dalam Excel berdasarkan kategori
                let data = [];

                results.forEach(result => {
                    if (['Pola Normal', 'Pola Poisson'].includes(this.fittingDataResult.kategori)) {
                        data.push({
                            'Material': result.material,
                            'Kategori': result.kategori,
                            'Service Level': result.service_level,
                            'Safety Stock': result.safety_stock,
                            'ROP': result.ROP,
                            'EOQ': result.EOQ + ' ' + this.fittingDataResult.unit_of_measurement,
                            'Total Cost Optimal': this.formatRupiah(result.total_cost_optimal),
                        });
                    } else if (this.fittingDataResult.kategori === 'Pola Tak - Tentu') {
                        data.push({
                            'Material': result.material,
                            'Kategori': result.kategori,
                            'Safety Stock': result.safety_stock,
                            'Total Cost Optimal': this.formatRupiah(result.total_cost_optimal),
                        });
                    } else if (this.fittingDataResult.kategori === 'Pola Non - Moving') {
                        data.push({
                            'Material': result.material,
                            'Metode': result.metode,
                            'Safety Stock': result.safety_stock,
                            'Total Cost Optimal': this.formatRupiah(result.total_cost_optimal),
                        });
                    } else if (this.fittingDataResult.kategori === 'Pola Deterministik') {
                        data.push({
                            'Material': result.material,
                            'Kategori': result.kategori,
                            'ROP': result.ROP,
                            'EOQ': result.EOQ + ' ' + this.fittingDataResult.unit_of_measurement,
                            'Total Cost Optimal': this.formatRupiah(result.total_cost_optimal),
                        });
                    }
                });

                // Membuat worksheet dan workbook
                const ws = XLSX.utils.json_to_sheet(data);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Results');

                // Membuat file Excel
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                // Mendapatkan tanggal saat ini
                const now = new Date();
                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0');
                const day = String(now.getDate()).padStart(2, '0');

                // Menambahkan tanggal ke nama file
                const fileName = `calculation_result_${this.fittingDataResult.kategori}_${year}-${month}-${day}.xlsx`;

                // Membuat file blob untuk diunduh
                const fileUrl = window.URL.createObjectURL(new Blob([excelBuffer]));

                // Membuat elemen <a> sementara untuk download file
                const link = document.createElement('a');
                link.href = fileUrl;
                link.setAttribute('download', fileName);

                // Menambahkan link ke body dan memicu klik untuk mendownload file
                document.body.appendChild(link);
                link.click();

                // Membersihkan elemen setelah download selesai
                link.remove();
                window.URL.revokeObjectURL(fileUrl);
            } catch (error) {
                // console.error('Gagal membuat dan mendownload file Excel:', error);
                this.$notify({
                    message: 'Gagal membuat dan mendownload file Excel: ' + error,
                    type: 'danger',
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
            } finally {
                this.loadingDownload = false;
            }
        },
        openMatrixDialog() {
            this.matrixDialogVisible = true;
        },
        handleDialogClose() {
            console.log('Dialog Perhitungan ditutup')
        },
        getStockStrategyFromCsv() {
            const fileCsvPath = '/assets/source_stock_calc_single_material_stock_or_non_stock/Rekapan_SHP_Material.csv'
            
            fetch(fileCsvPath).then(response => {
                if (!response.ok) {
                    this.$notify({
                        message: "File Stock Strategy tidak ditemukan.",
                        type: "danger",
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                    return false
                }
                return response.arrayBuffer();
            })
            .then(arrayBuffer => {
                const data = new Uint8Array(arrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0]; // Mengambil sheet pertama
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                if (jsonData.length === 0) {
                    // this.$message.warning('File yang diambil kosong.');
                    // throw new Error('File kosong');
                    this.$notify({
                        message: "File yang diambil kosong.",
                        type: "danger",
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                    return false
                }

                this.stockStrategyData = jsonData.map((item, index) => ({
                    no: index + 1,
                    material_code: item['Material Code'],
                    description: item['Description'],
                    anper: item['Anper'],
                    stock_strategy: item['Stock Strategy'],
                    display_stock_strategy: item['Display Stock Strategy']
                }))
            })
            .catch(error => {
                 this.$notify({
                    message: 'Kesalahan memproses file: ' + error,
                    type: "danger",
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
            });
        },
        // removeServiceLevel(key) {
        //     this.confirmDialog(this.tt("confirm_delete")).then(result => {
        //         if (result.value) {
        //             this.serviceLevel.splice(key, 1)
        //         }
        //     });
        // },
        async dependCompany() {
            var params = JSON.stringify({
                "Perusahaan": this.anakPerusahaan
            })
            var config = {
                method: 'post',
                url: 'https://api-fm.pupuk-indonesia.com/material-list',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: params
            };
            await axios(config).then(response => this.materialNumbers = response.data["Material"]).catch(error => {
                this.$notify({
                    message: 'Retrieved Data Company Failed',
                    type: 'danger',
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
            })
                .catch(() => this.materialNumbers = []);
        },

    }
};
</script>

<style scoped>
.el-tooltip__popper {
    max-width: 320px !important;
    word-break: break-word;
}

.customTableKecil tbody tr th,
td {
    padding: 6px !important;
    text-align: center;
}

.customTableInputKecil tbody tr td input {
    height: 30px !important;
    width: 100% !important;
    text-align: center;
}

/* sahrul */
.btn-yellow {
    background-color: rgb(252, 165, 3) !important;
    /* Warna latar belakang kuning */
    color: rgb(8, 7, 7) !important;
    /* Warna teks hitam */
    border-color: rgb(221, 201, 68) !important;
    /* Warna tepi kuning */
}

.btn-yellow:hover {
    background-color: darkorange !important;
    /* Warna latar belakang kuning tua saat dihover */
    border-color: darkorange !important;
    /* Warna tepi kuning tua saat dihover */
    color: rgb(239, 231, 231) !important;
    /* Warna teks hitam */
}

/* pop up matrix */
.table-container {
  overflow-x: auto;
  /* margin-bottom: 1rem; */
}

.matrix-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 10px; /* Ukuran font lebih kecil */
  min-width: 600px; /* Lebar minimum lebih kecil */
}

.matrix-table th,
.matrix-table td {
  border: 1px solid #0d0909; /* Border lebih tipis */
  padding: 5px; /* Padding lebih kecil */
}

.matrix-table th {
  background-color: #2a4f8a;
  color: white;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
}

.variable-header {
  background-color: #009688;
}

.method-header {
  background-color: #2196f3;
}

.sub-header {
  background-color: #64b5f6;
}

.bcr-header {
  background-color: #ffc107;
}

.matrix-table tbody td {
  background-color: #f9f9f9;
}

.matrix-table tbody tr:nth-child(even) td {
  background-color: #fff;
}

.table-header-center {
  text-align: center;
  font-weight: bold;
}

/* button fitting, reset matrix*/
.base-button {
    display: inline-flex; /* Tombol akan menyesuaikan dengan teks */
    align-items: center; /* Vertikal alignment teks */
    font-size: 0.647rem; /* Ukuran font default (misalnya, 14px) */
    padding: 12px 6px; /* Padding yang konsisten */
    border: none; /* Menghapus border default jika diperlukan */
    text-align: center; /* Menyelaraskan teks di tengah */
    margin: 0; /* Hapus margin */
    min-width: auto; /* Pastikan tombol tidak memiliki lebar minimum tetap */
    transform: scale(1); /* Pastikan transformasi tidak mempengaruhi ukuran */
}

.d-flex {
    display: flex; /* Menggunakan Flexbox untuk tata letak */
    align-items: center; /* Vertikal alignment konten */
    flex-wrap: wrap; /* Membungkus tombol jika ruang tidak cukup */
}

.base-button + .base-button {
    margin-left: 4px; /* Ruang antar tombol jika diperlukan */
}

/* Media query untuk ukuran font responsif jika diperlukan */
@media (max-width: 768px) {
  .base-button {
      font-size: 0.75rem; /* Ukuran font lebih kecil pada layar lebih kecil */
      padding: 4px 8px; /* Padding yang lebih kecil */
  }

  .matrix-table {
    font-size: 8px; /* Ukuran font lebih kecil pada layar kecil */
  }

  .matrix-table th,
  .matrix-table td {
    padding: 4px; /* Padding lebih kecil pada layar kecil */
  }

  .matrix-table th,
  .matrix-table td {
    white-space: nowrap; /* Mencegah teks membungkus pada ukuran kecil */
  }
}



</style>